import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueLazyload from "vue-lazyload";
import "../src/assets/css/base.css";
Vue.use(Antd);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("@/assets/img/kctp.png"),
  loading: require("@/assets/img/kctp.png"),
  attempt: 1,
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
